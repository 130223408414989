import React from 'react'
import { graphql, Link } from 'gatsby'
import Seo from '../../components/seo'
import Layout from '../../components/layout'
import Image from '../../components/uielements/Image'
import ReadMore from '../../components/buildingBlocks/ReadMore'
import Flex from '../../components/uielements/Flex'
import FlexWrapper from '../../components/uielements/flexWrapper'
import Spacer from '../../components/uielements/Spacer'
import MainTitle from '../../components/uielements/MainTitle'
import BlogSlider from '../../components/buildingBlocks/BlogSlider'
import InnerTitle from '../../components/uielements/InnerTitle'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Columns from '../../components/uielements/Columns'
import Wrapper from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import RichContent from '../../components/uielements/RichContent/richContent'
import Typeform from '../../components/external/Typeform'

const ProgramBox = styled.div`
    display: block;
    box-shadow: 0 0 14px #ccc;
    margin: 20px 20px;
    text-align: center;
    color: #fff;
    font-size: 1.2em;
    border-radius: 6px;

    a {
        color: ${({ theme }) => theme.blue};
        border: 4px solid ${({ theme }) => theme.blue};
        padding: 50px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        text-decoration: none;
        border-radius: 6px;
        height: 120px;

        &:hover {
            background: ${({ theme }) => theme.blue};
            color: #fff;
        }
    }
`

const ChildrenPage = ({ data: { child } }) => {
    const { t, i18n } = useTranslation()
    return (
        <Layout>
            <Helmet>
                {child.featuredImage && (
                    <meta
                        property="og:image"
                        content={child.featuredImage.url}
                    />
                )}
                <meta property="og:title" content={child.title} />
            </Helmet>
            <Wrapper>
                <Seo title={child.title} />
                <MainTitle>{child.title}</MainTitle>
                <Spacer top="2" />
                <Columns>
                    <Image obj={child.featuredImage} />
                    <RichContent body={child?.body?.raw} />
                    <Spacer top="3" bottom="3" />
                    {i18n.language === 'el-GR' && <ReadMore {...child} />}
                </Columns>
                <Spacer top="5" />
                {child?.typeformId !== '' && child?.typeformId !== null && (
                    <>
                        <Typeform
                            width="100%"
                            height="65vh"
                            formID={child.typeformId}
                        />
                        <Spacer top="6" />
                    </>
                )}
                {child.relatedPrograms && (
                    <InnerTitle>{t('related-programs')}</InnerTitle>
                )}
                <FlexWrapper xl={{ margin: '0 -10px 0 -10px' }}>
                    {child.relatedPrograms &&
                        child.relatedPrograms.map(
                            program =>
                                i18n.language === program.node_locale && (
                                    <Flex
                                        flexBasis="100%"
                                        key={program.id}
                                        sm={{ flexBasis: '100%' }}
                                        md={{ flexBasis: '100%' }}
                                        lg={{ flexBasis: '100%' }}
                                    >
                                        <ProgramBox>
                                            <Link
                                                title={program.title}
                                                to={`/program/${program.slug}`}
                                                key={program.id}
                                            >
                                                {program.title}
                                            </Link>
                                        </ProgramBox>
                                    </Flex>
                                )
                        )}
                </FlexWrapper>
                <Spacer top="5" />
                <BlogSlider />
            </Wrapper>
        </Layout>
    )
}

export const query = graphql`
    query children($slug: String) {
        child: contentfulPage(slug: { eq: $slug }) {
            ...ContentfulChildrenPageFragment
        }
    }

    fragment ContentfulChildrenPageFragment on ContentfulPage {
        id
        node_locale
        slug
        title
        updatedAt
        body {
            raw
        }
        typeformId
        readMore {
            slug
            title
            postType
        }
        featuredImage {
            url
            title
            gatsbyImageData(
                quality: 75
                jpegProgressive: true
                sizes: "1920, 960, 480"
                cropFocus: CENTER
            )
        }
        relatedPrograms {
            slug
            title
            node_locale
        }
    }
`

export default ChildrenPage
